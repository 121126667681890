import type { Nullish } from "~/@types/generics"
import type { TEmbroidery } from "~/components/ui/Product/ProductHeader/_data/serialize-embroidery"

type EmbroideriesFormData = { value?: Nullish<string>; picto?: Nullish<string>; text?: string }[]

export const EMBROIDERY_NONE_PICTO = "none"

export function getEmbroideriesWithValues(embroideries: EmbroideriesFormData) {
  return embroideries.flatMap(({ value, picto }) =>
    (value && value !== "") || (picto && picto !== EMBROIDERY_NONE_PICTO)
      ? [{ ...(value && value !== "" && { value }), ...(picto && picto !== EMBROIDERY_NONE_PICTO && { picto }) }]
      : []
  )
}

export function getCurrentEmbroideryProduct(embroideries: EmbroideriesFormData, embroidery: TEmbroidery) {
  const embroideriesFilter = embroideries?.filter(
    (embroidery) => embroidery.value !== "" || embroidery.picto !== EMBROIDERY_NONE_PICTO
  )
  const embroideriesCount = getEmbroideriesWithValues(embroideriesFilter).length

  switch (embroideriesCount) {
    case 0:
      return null
    case 1:
      return embroidery?.oneEmbroideryProduct ?? null
    case 2:
      return embroidery?.twoEmbroideryProduct ?? null
    default:
      null
  }
}

export function sanitizeEmbroideryPrice(price: Nullish<string>) {
  return price ? `(+${price})` : ""
}
